body { 
  padding-top: 3rem; 
  padding-bottom: 3rem; 
  max-width: 400px; 
  margin: 0px auto; 
}

.navbar { 
  max-width: 400px; 
  margin: 0px auto; 
}

.logo {
  max-width: 150px;
}

.navbar-brand { 
  margin: 0px;
}

.mealButton {
  color: rgb(33, 37, 41);
}

.addMealButton {
  padding-top: .4em;
  padding-bottom: .4em;
  margin-left: auto;
  margin-right: auto;
  color: #ccc;
}

.ant-table-thead > tr > th {
  padding: 8px !important;
}

.ant-table-tbody > tr > td {
  padding: 8px !important;
}

.ig-stores > .input-group-text {
  width: 80%;
  font-size: .9em;
}

.ig-stores > input {
  font-size: .9em;
}

.menu-today {
  font-size: .9em;
  background-color: yellow;
}

.about-text {
  font-size: .8em;
}

.offcanvas-main {
  max-width: 60%;
  margin: 0px auto;
}

.about-div {
  position: fixed;
  bottom: 0;
}

div.offcanvas-main a {
  text-decoration: none;
  font-size: 1.1em;
  color: #333;
}

.menu-text {
  font-size: .7em;
}

.addMenu-button {
  font-size: .7em;
  padding-top: .8em;
  padding-bottom: .8em;
}

.addMenu-button b {
  font-size: 1.1em;
}

.modal-buttons {
  border-top: 1px solid #eee;
  padding-top: 1em;
}

.flex-container {
  display: flex;
  align-items: center;
}

.flex-grow-top {
  flex-grow: 1;
  z-index: 100001;
}

.flex-grow-bottom {
  flex-grow: 1;
  z-index: 100000;
}

.fixed-top {
  z-index: 100002;
}

div.fade.modal.show {
  z-index: 100003;
}

.offcanvas-main {
  z-index: 100004;
}

.offcanvas-option {
  margin-bottom: .5em;
  font-size: 1.2em;
}

.offcanvas-backdrop, .modal-backdrop {
  z-index: 100003;
}

.item-meal-list {
  font-size: .9em;
}

.store-aisle > input {
  font-size: 1em;
  /* padding-top: .8em;
  padding-bottom: .8em; */
}

.store-aisle .aisle-input {
  max-width: 3.5em;
  text-align: center;
}

.dot {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: inline-block;
}

.dotSelect {
  width: 40px;
  font-size: 3em;
  line-height: .1em;
}

.navColor {
  color: #4d4343;
}

.ant-tabs-dropdown {
  z-index: 100002;
}

#shop-tabs .ant-tabs-nav {
  margin-bottom: 0px;
}

#navbar-shop {
  display: none;
  --bs-navbar-padding-y: 0rem;
}

.shopping-mode-text {
  padding-top: .25em;
  padding-bottom: .25em;
  font-size: .8em;
  margin-left: auto;
  margin-right: auto;;
}

.groceryPicker {
  font-size: 1em;
}

.avatar {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  box-shadow: 2px 2px 2px 1px rgb(0 0 0 / 30%);
}

.cat {
  border-radius: 5%;
  border: 2px solid rgb(167, 167, 167);
}
